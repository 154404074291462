@import 'styles';

.pipeline-details-table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: $spacings-md $spacings-lg;

  &--loading {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    div {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    span {
      margin-top: $spacings-sm;
    }
  }

  &__filters {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: $spacings-md;

    :last-child {
      margin-left: auto;
    }
  }

  &__filter-button {
    display: inline-flex;
    align-items: center;
    padding-left: $spacings-sm;

    svg {
      height: 24px;
      padding-right: $spacings-xxxs;
    }
  }

  &__filter-active-indicator {
    width: 10px;
    height: 10px;
    background-color: $syncari-blue;
    border-radius: 100%;
    margin-left: $spacings-xs !important;
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__destructive-action {
    color: $red-300;
  }
}
