.dataset-variable-popover {
  &--inputs {
    .ant-form-item {
      margin-bottom: 0;
    }
    &--datatype {
      &.input-component,
      .input-component {
        width: 316px;
      }
    }
    .synri-multi-value-text {
      .input-component {
        // Specific value for multi value in the variable form
        width: 316px;
      }
    }
  }

  &__datatype-option {
    display: flex;
  }

  &--footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 8px;
    }
  }
}
