@import 'styles';

$tab-bar-height: 49px;

.pipeline-details {
  width: 100%;
  height: calc(100% - $tab-bar-height);
  display: flex;
  background-color: white;

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray-300;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  &__table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__context-panel {
    flex-shrink: 0;
    min-width: 362px;
    max-width: 362px;
  }
}
