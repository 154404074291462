@import 'styles';

.truncated-cell {
  @include ellipsis-text();
}

.badge-cell {
  &__link {
    .synri-text-tag-container {
      span {
        cursor: pointer;
      }
    }
  }
}

.sink-source-cell {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: $spacings-xxxs $spacings-xs $spacings-xxxs 0;
  border: 1px solid $gray-600;
  border-radius: $border-radius-md;
  font-size: $font-size-xs;
  color: $gray-800;

  &__connector {
    padding: $spacings-xxs 0 $spacings-xxs $spacings-xxs;
  }

  &__divider {
    padding: 0 $spacings-xxs;
    color: $gray-600;
  }

  &__entity {
    padding-right: $spacings-xs;
  }

  &__icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-left: $spacings-xs;
    margin-right: $spacings-xxxs;

    svg {
      width: 100%;
      height: 100%;
    }

    &--bigger {
      margin-left: $spacings-xxs;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.tag-cell {
  &--warning {
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
  }
}
