.create-ai-assisted-data-card-button {
  display: flex !important;
  align-items: center !important;

  i {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
