@import '~styles/index.less';

.dataset-variable-configuration {
  padding: @spacings[lg];
  padding-left: 0;

  &__datatype-option {
    display: flex;
  }

  &__add-variable {
    padding-left: 0;
  }

  .synri-multi-value-text {
    .input-component {
      // Specific value for multi value in the list view
      width: 238px;
    }
  }
}
