@import 'styles';

.resync-modal {
  &--loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $spacings-lg 0;
  }

  .ant-select {
    width: 100%;
  }
}
